import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import React from "react";
import {  lazy  } from "react";
import Footer from "./components/Footer/Footer";
import NewsPage from "./pages/NewsPage/NewsPage";
import NewsDetail from "./pages/NewsDetails/NewsDetail";
import NavMenu from "./components/NavMenu/NavMenu";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import Spinner from "./components/Spinner/Spinner";
import Register from "./pages/Register/Register";
import Committees from "./pages/committees/committees";
const Members = lazy(() => import('./pages/Members/Members'));


function App() {

 

  return (
    <div className="App">
      <BrowserRouter basename={"/"}>
      <NavMenu />
      <React.Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path='/services' element={<Services />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path='/members' element={<Members />} />
          <Route path='/committees' element={<Committees />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/register' element={<Register />} />
        </Routes>
        </React.Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
