import React from 'react'
import './Spinner.scss' 

export default function Spinner() {

  
    return (
      <div className="loading">
        Loading ...
      </div>
    );
  }
  