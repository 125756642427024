import "./About.scss";
import { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpenReader } from "@fortawesome/free-solid-svg-icons";

export default function About() {
  const sectionRef = useRef(null);
  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          section.classList.add("visible");
          observer.disconnect();
        }
      });
    });
    observer.observe(section);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className="flex flex-col mt-20 lg:mr-20 md:mr-5 mr-5"
      ref={sectionRef}
      id="about"
    >
      <hr className="line border w-28 ml-auto " />
      <h4 className="section-main-heading lg:text-3xl md:text-2xl text-2xl pt-4 text-right font-semibold">
        تعرف على ساسيرو
      </h4>
      <div
        className="flex lg:flex-row md:flex-col flex-col"
        style={{ direction: "rtl" }}
      >
        <p className="text-slate-600 text-right lg:w-2/5 md:w-full w-full mt-10 font-normal px-4">
       <ul className="mt-5 flex flex-col gap-2">
        <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234",}} />
        	تعود حماية الملكية الوطنية الأدبية والفنية إلى عام 1709 حين صدر في بريطانيا قانون حق النسخ 
        </li>
        <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234",}} />
        	وفي فرنسا وأمريكا تقررت حماية حقوق المؤلف في العقد الأخير من القرن الثامن عشر.
        </li>
        <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234",}} />
        	كانت مصر الدولة العربية الأولى التي اتصلت بالفاعليات الدولية الخاصة بحقوق المؤلف ، إذ انها دعيت عام 1925 م للإنضمام إلى اتفاقية "برن" كما انها اشتركت في "مؤتمري روما وبلجراد"  حول حقوق المؤلف عام 1928م واستضافت عام 1929م مؤتمرأ عقدته الجمعية الأدبية والفنية الدولية وانضمت لإتفاقية "برن" عام 1977م.
        </li>
        <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234",}} />
        	ظلت حقوق المؤلف بغير حماية جدية حتى صدور أول قانون لحماية حق المؤلف رقم 354 لعام 1954م .فسد النقص في التشريع بنصوص صريحة وواضحة وقد استوحيت احكامه من الاتفاقات الدولية الخاصة بحق المؤلف ومن بعض التشريعات الحديثة حتى تم إلغاؤه بعد صدور قانون حماية حقوق الملكية الفكرية الحالي رقم 82 لعام 2002م.        </li>
        <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
        	لأن مصر ذات الحضارة العريقة و الموقع الجغرافي المتميز ،هي الدولة الأولى في المنطقة التي أنشأت بها الإذاعات الأهلية عام 1922 والإذاعة الرسمية عام 1934م ، وبدأ بها الإنتاج السينمائي مع بداية القرن العشرين علاوة على ماتملكه من مبدعين في عالم التأليف والتلحين والغناء والتأليف الموسيقي، فقد اختارتها الجمعية الفرنسية الساسم (SACEM) لتكون المقرالأول لنشاطها في الشرق ، لذلك أنشأت المكتب العربي لحق المؤلف بها عام 1944م ، وكان أول مديرفرنسي للمكتب هو مسيو لانسون.       
        </li>
         <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
	أخذت الجمعية في تنمية تحصيلات المكتب وتوالت أجيال من الأعضاء بأعمال جديدة مميزة .     
     </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
       	1960م تم توقيع بروتوكول لتبادل التحصيل مع الجمعية الفرنسية لتقوم بتحصيل حقوق المصريين من الخارج وتقوم مصر بتحصيل حقوق الأجانب من داخل مصر وأصبحت الجمعية عضو الأتحاد الدولي لجمعيات المؤلفين والملحنين  وعضو المجلس الدولي للتسجيل الميكانيكي عالميا.     </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
       	تقوم الجمعية بتوزيع عوائد أعضاءها على مدى أربع أوقات في العام الواحد ففي شهري يناير ويوليو توزع تحصيلات الخارج وفي شهري أبريل وأكتوبر توزع تحصيلات الداخل وتقوم الساسم باتمام العملية الحسابية الدقيقة وترسل في كشوف مفردة لكل عضو بما حصل له وكذلك كشف عام لكل الأعضاء به ما تحقق لكل عضو باليورو وما يساويه بالجنيه المصري.     </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
      	يخصم من توزيعات كل عضو نسبة ماعليه لصالح مصلحة الضرائب المصرية كما جاء في القانون ثم تسدد دوريا للدولة المصرية.     </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
      	يخصم من كل عضو 7% من مجموع دخله لصالح صندوق الرعايه بالجمعية و ذلك لرعاية الاعضاء العاملين صحيا و اجتماعيا و بعد بلوغ العضو الستين يحصل علي منحة شهريه و كذلك الحال بالنسبة للورثة من الارامل والقصرحسب اللائحة الداخليه للجمعية     </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
       	تحصل الجمعيه لاعضائها و اعضاء الجمعيه العالميه الساسم      </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
        	تحصل الساسم لمصر من الخارج للاعضاء المصرين     
          </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
      	بالجمعية اعضاء من الدول العربيه مثل المملكه العربيه السعودية _السودان _ ليبيا _فلسطين و لكن ليس لهم حق الانتخاب او الترشح     </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
       	تقبل الجمعيه للاعضاء الجدد اذا كان لديهم انتاجأ متميزأ  يحقق عائدأ لهم و للجمعيه بشرط ان يقدم العضو فيش و تشبيه لا شئ فيه ولا عليه ضمن ما يقدم من اوراق بها صورة رقمه القومي و كشف بانتاجه و طلب مكتوب باسم رئيس الجمعيه يرغب فيه الانضمام لها     </li>
     <li>
        <FontAwesomeIcon icon={faBookOpenReader} className="ml-2" style={{color: "#9f1234"}} />
        	الجمعيه :- جمعيه مركزيه بالقاهرة ولها مكتب بالأسكندريه  و جاري انشاء مكاتب اخري وهي الوحيدة في مصر خلف اعضائها     </li>
       </ul>
        </p>
        <div className="lg:w-2/5 md:w-full w-full md:mt-5 mt-10 lg:mr-auto md:mr-0 lg:ml-10 md:ml-0 flex border-b-2">
          <div className="relative h-full md:w-20 w-0">
            <div className="absolute inset-0 z-[-1] h-[450px] w-full skew-part transform origin-bottom-right skew-x-6"></div>
          </div>

          <img
            src={process.env.PUBLIC_URL + "/images/sacerau-about.png"}
            alt="Sacerau"
            className="lg:w-4/5 md:w-full w-full h-[450px]"
          />
        </div>
      </div>
    </div>
  );
}
