import { useState } from "react";
import { Link } from "react-router-dom";
import "./NavMenu.scss";
import NavIcons from "../NavIcons/NavIcons";
import CloseIcon from "../SvgIcons/CloseIcon";
import HamburgerMenu from "../SvgIcons/HamburgerMenu";

export default function NavMenu() {
  const [navbar, setNavbar] = useState(false);

  return (
    <nav
      className="w-full shadow header fixed top-0 z-30"
      style={{ direction: "rtl" }}
    >
      <div className="nav-icons justify-between h-full px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-4">
        <div>
          <div className=" flex items-center justify-between py-0 md:py-1  md:block">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo-sacerau.png"}
                alt="sacerau"
                className="bg-white logo lg:w-36 md:w-36 w-28"
              />
            </Link>
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? <CloseIcon /> : <HamburgerMenu />}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-2 lg:text-base md:text-base md:flex  md:space-x-0  md:space-y-0">
              <>
                <NavIcons navbar={navbar} setNavbar={setNavbar} />
              </>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
