import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpenReader } from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  return (
    <div className="a mt-32 md:px-0 px-3">
      <div className="lg:mr-20 md:mr-5 mr-0">
        <hr className="line border w-28 ml-auto" />
        <h4 className="section-main-heading lg:text-3xl md:text-2xl text-2xl pt-4 text-right font-semibold">
          تواصل معنا
        </h4>
        <div style={{ direction: "rtl" }} className="mt-10">
          <h6 className="footer-header mb-4 flex text-lg items-center justify-center font-bold uppercase md:justify-start underline">
            ساسيرو
          </h6>
          <p
            className="md:text-right text-center md:w-2/3 w-full"
            style={{ direction: "rtl" }}
          >
            <ul className="mt-5 flex flex-col gap-2">
              <li>
                <FontAwesomeIcon
                  icon={faBookOpenReader}
                  className="ml-2"
                  style={{ color: "#9f1234" }}
                />
                يقع مقر الجمعية الرئيسي في وسط القاهرة 10 شارع الألفي – القاهرة
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faBookOpenReader}
                  className="ml-2"
                  style={{ color: "#9f1234" }}
                />
                ويقع مكتب إمتداد نشاط الجمعية بالأسكندرية 19 شارع مرسي بدر
              </li>
            </ul>
          </p>
        </div>
        <div style={{ direction: "rtl" }} className="mt-10">
          <h6 className="footer-header mb-4 flex text-lg items-center justify-center font-bold uppercase md:justify-start underline">
            نبذة عن السنة المالية
          </h6>
          <p
            className="md:text-right text-center md:w-2/3 w-full"
            style={{ direction: "rtl" }}
          >
            <ul className="mt-5 flex flex-col gap-2">
              <li>
                <FontAwesomeIcon
                  icon={faBookOpenReader}
                  className="ml-2"
                  style={{ color: "#9f1234" }}
                />
                تبدأ السنة المالية للجمعية مع السنة المالية للدولة وتنتهي
                بنهايتها طبقا للائحة التنفيذية للقانون 149 لسنة 2019
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faBookOpenReader}
                  className="ml-2"
                  style={{ color: "#9f1234" }}
                />
                يعرض الحساب الختامي لها علي أحد المحاسبين المقيدين بجداول
                المراجعين القانونيين ثم العرض علي الجمعية العمومية .
              </li>
            </ul>
          </p>
        </div>
        <div style={{ direction: "rtl" }} className="mt-10">
          <h6 className="footer-header mb-4 flex text-lg items-center justify-center font-bold uppercase md:justify-start underline">
            نبذة عن الجمعية العمومية
          </h6>
          <p
            className="md:text-right text-center md:w-2/3 w-full"
            style={{ direction: "rtl" }}
          >
            <ul className="mt-5 flex flex-col gap-2">
              <li>
                <FontAwesomeIcon
                  icon={faBookOpenReader}
                  className="ml-2"
                  style={{ color: "#9f1234" }}
                />
                تتكون الجمعية العمومية من جميع الأعضاء العاملين المقيدين بدفاتر
                الجمعية وفقاً للوائح
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faBookOpenReader}
                  className="ml-2"
                  style={{ color: "#9f1234" }}
                />
                تنعقد الجمعية العمومية بموجب دعوة توجه من رئيس مجلس الادارة أو
                من يفوضه المجلس لكل من أعضاءها العاملين .
              </li>
            </ul>
          </p>
        </div>
        <div className="mt-10">
          <h6
            className="footer-header mb-4 flex text-lg items-center justify-center font-bold uppercase md:justify-start underline"
            style={{ direction: "rtl" }}
          >
            مواعيد العمل
          </h6>
          <p className="mb-4 md:text-right text-center">
            من الأحد إلى الخميس
            <br />
            من 9 ص إلى 7 مساءا
            <br />
            الجمعة : مغلق
          </p>
        </div>
      </div>
      <div className="flex lg:flex-row md:flex-row flex-col justify-center mt-10">
        <p className="mb-4 flex  items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#0d8188"
            className="md:ml-6 ml-0 mr-2 h-5 w-5"
          >
            <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
            <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
          </svg>
          10 شارع الألفي -وسط البلد
        </p>
        <p className="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#0d8188"
            className="md:ml-6 ml-0 mr-2 h-5 w-5"
          >
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
          </svg>
          <a href="mailto:info@sacerau.com">info@sacerau.com</a>
        </p>
        <p className="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#0d8188"
            className="md:ml-6 ml-0 mr-2 h-5 w-5"
          >
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clipRule="evenodd"
            />
          </svg>
          <a href="tel:+201061609885" style={{ direction: "ltr" }}>
            +20 106 160 9885
          </a>
        </p>
      </div>
      <div className="flex justify-center mt-10 lg:px-10 px-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.40904966369!2d31.241882876147653!3d30.053807318126033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458415b77373cff%3A0x8855b9e4607b8ddf!2z2KzZhdi52YrYqSDYp9mE2YXYpNmE2YHZitmGINmI2KfZhNmF2YTYrdmG2YrZhg!5e0!3m2!1sen!2seg!4v1683031490507!5m2!1sen!2seg"
          width="1000"
          height="600"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          title="جمعية المؤلفين و الملحنين"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
