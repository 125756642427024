import "./NewsPage.scss";
import NewsPageItem from "./NewsPageItem/NewsPageItem";
import { useState, useEffect } from "react";
import axios from "axios";

export default function NewsPage() {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (hasNextPage) {
      setIsLoading(true);
      axios
        .get(`https://sacerau.ivas.com.eg/api/news?limit=12&page=${page}`)
        .then((response) => {
          console.log(`page`, page);
          let newsAPI = response.data.data;
          setNews([...news, ...newsAPI]);
          setHasNextPage(response.data.links.next !== null);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [page]);

  const handleButtonClick = () => {
    if (hasNextPage) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="flex flex-col w-full ml-auto pb-10 mt-16" id="news-page">
      <hr className="border-pink-700 border w-28 ml-auto lg:mr-20 md:mr-5 mr-5 mt-20" />
      <h3 className=" lg:text-4xl text-2xl pt-4 text-right font-semibold lg:mr-20 md:mr-5 mr-5 section-main-heading ">
        أحدث أخبار الجمعية
      </h3>
      <p className="pt-4 lg:text-xl md:text-lg text-base ml-auto lg:mr-20 md:mr-5 mr-5 pl-5 flex items-center text-right font-light ">
        تابع معنا كل ماهو جديد مع اخبار جمعية المؤلفين والملحنين والناشرين
        المصرية واحدث اخبار النجوم والفنانين واخر اعمالهم على موقع ساسيرو
      </p>
      <div
        className="lg:mr-20 mt-8 grid lg:grid-cols-4 md:grid-cols-3  grid-cols-1 gap-7 lg:ml-20 lg:w-11/12 w-11/12 md:mr-0 mr-auto md:ml-10 ml-auto "
        style={{ direction: "rtl" }}
      >
        {news.map((newsItem) => (
          <NewsPageItem
            key={newsItem.id}
            id={newsItem.id}
            img={newsItem.image}
            description={newsItem.description}
            title={newsItem.title}
          />
        ))}
      </div>
      {isLoading && (
        <div
          className="inline-block ml-auto text-red-700 mr-auto mt-8 h-14 w-14 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-success motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      )}
      {hasNextPage && !isLoading && news.length > 0 && (
        <button
          className="mt-8 showMoreButton font-bold lg:text-base sm:text-sm hover:underline rounded-lg border lg:w-2/12 md:w-4/12 w-6/12 mr-auto ml-auto px-2 py-2"
          onClick={handleButtonClick}
        >
          المزيد من الأخبار
        </button>
      )}
      {!hasNextPage && (
        <div className="text-center mt-16 noNews font-bold text-lg">
          لا يوجد مزيد من الأخبار
        </div>
      )}
    </div>
  );
}
