import "./NavIcons.scss";
import { Link, useLocation } from "react-router-dom";
function useIsActiveRoute(route) {
  const location = useLocation();
  return location.pathname === route;
}
export default function NavIcons({ navbar, setNavbar }) {
  const homeIsActive = useIsActiveRoute("/");
  const committeesIsActive = useIsActiveRoute("/committees");
  const servicesIsActive = useIsActiveRoute("/services");
  const membersIsActive = useIsActiveRoute("/members");
  const contactIsActive = useIsActiveRoute("/contact");
  const newsIsActive = useIsActiveRoute("/news");
  const registerIsActive = useIsActiveRoute("/register");

  const handleClick = () => {
    window.scrollTo(0, 0);
    setNavbar(!navbar);
  };

  return (
    <>
      <li
        className={`${
          homeIsActive ? "colored relative text-white" : ""
        }relative text-white`}
      >
        <Link
          to="/"
          onClick={handleClick}
          className="block cursor-pointer text-center lg:ml-8 md:ml-3 h-full py-2 pl-3 pr-1   rounded  md:hover:bg-transparent md:border-0   md:p-0"
        >
          الرئيسية
        </Link>
        {homeIsActive && (
          <span
            className={`${
              homeIsActive
                ? "transform scale-x-100 absolute lg:block  md:hidden hidden right-0 underline-hover w-full h-1"
                : ""
            }`}
          ></span>
        )}
      </li>
      <li
        className={`${
          servicesIsActive ? "colored relative text-white" : ""
        }relative text-white`}
      >
        <Link
          to="/services"
          onClick={handleClick}
          className="block cursor-pointer text-center lg:ml-8 md:ml-3 h-full py-2 pl-3 pr-1   rounded  md:hover:bg-transparent md:border-0   md:p-0"
        >
          خدمات الجمعية
        </Link>
        {servicesIsActive && (
          <span
            className={`${
              servicesIsActive
                ? "transform scale-x-100 absolute lg:block  md:hidden hidden right-0 underline-hover w-full h-1"
                : ""
            }`}
          ></span>
        )}
      </li>
      <li
        className={`${
          committeesIsActive ? "colored relative text-white" : ""
        }relative text-white`}
      >
        <Link
          to="/committees"
          onClick={handleClick}
          className="block cursor-pointer text-center lg:ml-8 md:ml-3 h-full py-2 pl-3 pr-1   rounded  md:hover:bg-transparent md:border-0  md:p-0"
        >
          اللجان
        </Link>
        {committeesIsActive && (
          <span
            className={`${
              committeesIsActive
                ? "transform scale-x-100 absolute lg:block  md:hidden hidden right-0 underline-hover w-full h-1"
                : ""
            }`}
          ></span>
        )}
      </li>
      <li
        className={`${
          membersIsActive ? "colored relative text-white" : ""
        }relative text-white`}
      >
        <Link
          to="/members"
          onClick={handleClick}
          className="block cursor-pointer text-center lg:ml-8 md:ml-3 h-full py-2 pl-3 pr-1   rounded  md:hover:bg-transparent md:border-0  md:p-0"
        >
          أعضاء الجمعية
        </Link>
        {membersIsActive && (
          <span
            className={`${
              membersIsActive
                ? "transform scale-x-100 absolute lg:block  md:hidden hidden right-0 underline-hover w-full h-1"
                : ""
            }`}
          ></span>
        )}
      </li>
      <li
        className={`${
          newsIsActive ? "colored relative text-white" : ""
        }relative text-white`}
      >
        <Link
          to="/news"
          onClick={handleClick}
          className="block cursor-pointer text-center lg:ml-8 md:ml-3 h-full py-2 pl-3 pr-1   rounded  md:hover:bg-transparent md:border-0 md:p-0"
        >
          أخبار الجمعية
        </Link>
        {newsIsActive && (
          <span
            className={`${
              newsIsActive
                ? "transform scale-x-100 absolute lg:block  md:hidden hidden right-0 underline-hover w-full h-1"
                : ""
            }`}
          ></span>
        )}
      </li>
      <li
        className={`${
          registerIsActive ? "colored relative text-white" : ""
        }relative text-white`}
      >
        <Link
          to="/register"
          onClick={handleClick}
          className="block cursor-pointer text-center lg:ml-8 md:ml-3 h-full py-2 pr-1   rounded  md:hover:bg-transparent md:border-0  md:p-0"
        >
          سجل بصوتك
        </Link>
        {registerIsActive && (
          <span
            className={`${
              registerIsActive
                ? "transform scale-x-100 absolute lg:block  md:hidden hidden right-0 underline-hover w-full h-1"
                : ""
            }`}
          ></span>
        )}
      </li>
      <li
        className={`${
          contactIsActive ? "colored relative text-white" : ""
        }relative text-white`}
      >
        <Link
          to="/contact"
          onClick={handleClick}
          className="block cursor-pointer text-center lg:ml-8 md:ml-3 h-full py-2 pr-1   rounded  md:hover:bg-transparent md:border-0  md:p-0"
        >
          اتصل بنا
        </Link>
        {contactIsActive && (
          <span
            className={`${
              contactIsActive
                ? "transform scale-x-100 absolute lg:block  md:hidden hidden right-0 underline-hover w-full h-1"
                : ""
            }`}
          ></span>
        )}
      </li>
    </>
  );
}
