import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function NewsElement({ img, title, id }) {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="bg-white lg:w-1/5 md:w-2/5 w-5/6 cursor-pointer">
        <Link to={`/news/${id}`} onClick={handleClick}>
          <img src={img} alt={title} className="w-full h-60" />
          <p className="pt-6 pb-6 lg:text-base md:text-sm text-sm text-center px-2 block items-center justify-center font-light paragraph">
            {title}
          </p>
          <button
            className="ml-5 float-left text-slate-700 pb-6"
            onClick={handleClick}
          >
            <Link to={`/news/${id}`} className="more-details">
              عرض الخبر
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="text-sm mr-2"
                color="#0B9095"
              />
            </Link>
          </button>
        </Link>
      </div>
    </>
  );
}
