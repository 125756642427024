import "./ScrollToTop.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

export default function ScrollToTop() {
  // handling the visibilty of Scroll to top button
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 1200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
  }, []);

  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <button
          onClick={handleScrollToTop}
          className="fixed lg:bottom-11 up-circle upButton lg:right-11 md:bottom-5 md:right-5 right-3 bottom-3"
          title="إلى أعلى"
        >
          <FontAwesomeIcon
            icon={faArrowUp}
            color="#fff"
            size="xl"
            className=" lg:px-4 px-3 md:text-base text-base md:px-3 lg:py-4 md:py-3 py-3"
          />
        </button>
      )}
    </>
  );
}
