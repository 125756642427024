import "./NewsDetail.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

export default function NewsDetail() {

  const { id } = useParams();

  const [selectedNews, setSelectedNews] = useState([]);
 
  useEffect(() => {
    axios
      .get(`https://sacerau.ivas.com.eg/api/news/show/${id}`)
      .then((response) => setSelectedNews(response.data.news))
      .catch((error) => console.error(error));
  }, [id]);
  console.log(selectedNews)
  const newsDescription = { __html: selectedNews.description };

 
    return (
      <div className="lg:px-80 px-0 lg:mr-10 mr-0  mt-32">
       
        <hr className="line border w-28 ml-auto " />
        <h3 className="section-main-heading text-right  text-xl pt-10 lg:px-0 px-4 font-semibold text-pink-700">
          {selectedNews.title}
        </h3>
        <img
          src={selectedNews.image}
          alt="news"
          className="lg:px-0 px-4 text-right ml-auto mt-10"
        />
        <p
          className="mt-20 w-full  lg:pl-20 md:pl-0 lg:px-0 px-4 text-right ml-auto text-base font-normal"
          dangerouslySetInnerHTML={newsDescription}
        ></p>
      </div>
    );
}
