import "./News.scss";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import NewsElement from "./NewsElement/NewsElement";

export default function News() {
  const [news, setNews] = useState([]);
  useEffect(() => {
    axios
      .get("https://sacerau.ivas.com.eg/api/news?limit=4")
      .then((response) => setNews(response.data.data))
      .catch((error) => console.error(error));
  }, []);

  const sectionRef = useRef(null);
  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          section.classList.add("visible");
          observer.disconnect();
        }
      });
    });
    observer.observe(section);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      className="flex flex-col mt-20 lg:pl-10 md:pl-0 pl-0 news-component w-full ml-auto pb-10"
      id="news"
      ref={sectionRef}
    >
      <hr className=" border w-28 ml-auto lg:mr-20 md:mr-5 mr-5 mt-20" />
      <h3 className="lg:text-3xl md:text-2xl text-2xl pt-4 text-right font-bold lg:mr-20 md:mr-5 mr-5 text-white">
        أحدث أخبار الجمعية
      </h3>
      <p className="text-white text-right lg:pr-20 md:pr-5 pr-5 md:text-xl text-lg font-normal pt-16 w-full">
        تابع معنا كل ماهو جديد مع اخبار جمعية المؤلفين والملحنين والناشرين
        المصرية واحدث اخبار النجوم والفنانين واخر اعمالهم على موقع ساسيرو
      </p>
      <div
        className="lg:mr-20 md:mr-0 mr-0 md:justify-around justify-start items-center mt-8 flex flex-wrap md:flex-row flex-col gap-10"
        style={{ direction: "rtl" }}
      >
        {news.map((newsItem) => (
          <NewsElement
            img={newsItem.image}
            title={newsItem.title}
            description={newsItem.description}
            id={newsItem.id}
          />
        ))}
      </div>

      <button className="f mr-auto ml-auto mt-7 text-white px-4 py-4 rounded-3xl hover:underline">
        <Link
          to="/news"
          onClick={handleClick}
          className="border more-news px-4 py-4 rounded-3xl"
        >
          المزبد من الأخبار
        </Link>
      </button>
    </div>
  );
}
