import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function NewsPageItem({ img, title , id }) {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div
        className="bg-white flex-1 flex items-center w-full  flex-col  cursor-pointer shadow shadow-slate-400"
        key={id}
      >
        <Link to={`/news/${id}`} onClick={handleClick}>
          <img src={img} alt={title} className="w-full h-56" />
          <p className="pt-6 pb-6 lg:text-base md:text-sm text-sm text-center px-2 items-center justify-center font-light block paragraph">
        {title}
          </p>
          <button
            className="ml-5 float-left  more-details pb-6"
            onClick={handleClick}
          >
            <Link to={`/news/${id}`}>
              عرض المزيد
              <FontAwesomeIcon icon={faArrowLeft} className="text-sm" />
            </Link>
          </button>
        </Link>
      </div>
    </>
  );
}
