import "./HomePage.scss";
import CarouselSection from "../../components/CarouselSection/CarouselSection";
import Services from "../../components/Services/Services";
import VideoSection from "../../components/VideoSection/VideoSection";
import News from "../../components/News/News";
import Partners from "../../components/Partners/Partners";
import About from "../../components/About/About";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

export default function HomePage({news}) {
  return (
    <>
      <CarouselSection />
      <Services />
      <VideoSection />
      <News news={news}/>
      <Partners />
      <About />
      <ScrollToTop />
    </>
  );
}
