import "./VideoSection.scss";
import YouTube from "react-youtube";
import { useEffect, useRef } from "react";

export default function VideoSection() {
  const sectionRef = useRef(null);
  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          section.classList.add("visible");
          observer.disconnect();
        }
      });
    });
    observer.observe(section);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className="video-section flex flex-col mt-16 "
      id="video"
      ref={sectionRef}
    >
      <hr className="line border w-28 ml-auto lg:mr-20 md:mr-5 mr-5" />
      <h4 className="section-main-heading video-header text-right ml-auto lg:mr-20 md:mr-5 mr-5 lg:text-4xl md:text-3xl text-2xl font-semibold pt-8">
        لقاء الشاعر الكبير سيد حجاب
      </h4>
      <div className="flex lg:flex-row md:flex-col flex-col video-sec-container">
        <div className="overflow-hidden lg:w-4/6 md:w-full w-full video">
          <div className="lg:py-10 md:py-5 h-full py-5 lg:pr-36 md:px-0 px-0  lg:mt-0 md:mt-10 mt-10 video-card transform  lg:skew-x-12 md:skew-x-0 skew-x-0 origin-bottom-right w-full">
            <div className="h-full transform lg:-skew-x-12 md:-skew-x-0  -skew-x-0   lg:left-6 md:left-auto lg:pl-40 md:pl-0 p-0 w-full">
              <YouTube
                videoId="HDz1-Zqb1Ms"
                className="lg:px-0 px-5 videoIcon"
                opts={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-2/5 md:w-full w-full flex flex-col md:mr-20 mr-5 text-right video-text">
          <p
            className="text-slate-600 md:px-2 px-4 py-14 font-normal"
            style={{ direction: "rtl" }}
          >
            الشاعر الكبير سيد حجاج و لقاء خاص فى برنامج معكم منى الشازلى على
            قناة CBC ويناقش حقوق المؤلف فى مصر و دور جمعية المؤلفين و
            الملحنين
            <br />
            تناول الشاعر الكبير سيد حجاج أغنية يا طير لمحمد منير و أغنية سلم لنا
            بقى ع التورماى كما تناول أيضا الحديث عن تتر مسلسل جحا المصرى و
            العديد من المواضيع .
          </p>
          <p className="text-slate-600 md:px-2 px-4 py-3 font-normal">
            فى لقاء خاص على قناة سى بى سى التقى الشاعر الكبير سيد حجاج مع
            الإعلامية منى الشازلى و تناول الشاعر الكبير اهم اعماله و تحدث عن
            حقوق المؤلف فى مصر كما تناول ايضا دور جمعية المؤلفين و الملحنين.
          </p>
        </div>
      </div>
    </div>
  );
}
