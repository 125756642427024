import "./Services.scss";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faUserDoctor,
  faSquarePollHorizontal,
  faMicrophone
} from "@fortawesome/free-solid-svg-icons";

export default function Services() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const sectionRef = useRef(null);
  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          section.classList.add("visible");
          observer.disconnect();
        }
      });
    });
    observer.observe(section);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="mt-20" id="services" ref={sectionRef}>
      <hr className="line border w-28 ml-auto lg:mr-20 mr-5" />
      <h3 className="section-main-heading lg:text-3xl md:text-2xl text-2xl pt-4 text-right lg:mr-20 md:mr-5 mr-5 font-semibold">
        خدمات الجمعية
      </h3>
      <p
        className="mt-10 text-right lg:px-20 px-8 lg:w-2/3 md:w-full w-full ml-auto"
        style={{ direction: "rtl" }}
      >
        <span className="font-bold text-lg" style={{ direction: "rtl" }}>
            تشمل مجالات عمل الجمعية :
        </span>
        الخدمات الثقافية و العلمبة - المساعدات الإجتماعية - الرعاية الأسرية - الخدمات الصحية - النشاطات الأدبية
      </p>
      <div className="services-container flex flex-col  md:flex-row w-full md:justify-around items-center  flex-wrap h-full md:items-stretch ">
        <div className="service-card mt-8 lg:w-1/4 md:w-60 w-5/6 card-item flex flex-col text-white justify-center items-center  cursor-pointer lg:px-10 md:px-6 px-10 lg:py-8 md:py-5 py-5 shadow-xl shadow-slate-400 hover:bg-white">
          <FontAwesomeIcon
            icon={faUserDoctor}
            className="card-icon mt-8 text-7xl text-white"
          />
          <h5 className="service-heading lg:text-xl md:text-base text-base mt-12 font-semibold">
            الرعاية الصحية
          </h5>
          <p className="service-paragraph text-center mt-6">
            يمكنك تقديم طلبك لعمل الرعاية الصحية المطلوبة من بالتعاون مع جمعية
            ساسيرو
          </p>
          <button className="service-button bg-transparent border-white border text-white mt-10 md:px-6 px-6 py-4 rounded-3xl font-extrabold lg:text-xl md:text-base text-base">
            طلب رعاية صحية
          </button>
        </div>
        <div className="service-card mt-8 lg:w-1/4 md:w-60 w-5/6 card-item flex flex-col text-white justify-center items-center  cursor-pointer lg:px-10 md:px-6 px-10 lg:py-8 md:py-5 py-5 shadow-xl shadow-slate-400 hover:bg-white">
          <FontAwesomeIcon
            icon={faSquarePollHorizontal}
            className="card-icon mt-8 text-7xl text-white"
          />
          <h5 className="service-heading lg:text-xl md:text-base text-base mt-12 font-semibold">
            مقترحات واَراء
          </h5>
          <p className="service-paragraph text-center mt-6">
            إذا كان لديك أى إقتراح لتساعدنا على تطور جمعية ساسيرو حتى نقوم
            بتقديم أفضل الخدمات لديك
          </p>
          <button className="service-button bg-transparent border-white border text-white mt-10 md:px-6 px-6 py-4 rounded-3xl font-extrabold lg:text-xl md:text-base text-base">
            قدم مقترحك
          </button>
        </div>
        <div className="service-card mt-8 lg:w-1/4 md:w-60 w-5/6 card-item flex flex-col text-white justify-center items-center  cursor-pointer lg:px-10 md:px-6 px-10 lg:py-8 md:py-5 py-5 shadow-xl shadow-slate-400 hover:bg-white">
          <FontAwesomeIcon
            icon={faMicrophone}
            className="card-icon mt-8 text-7xl text-white"
          />
          <h5 className="service-heading lg:text-xl md:text-base text-base mt-12 font-semibold">
            تسجيل الأعمال الجديدة
          </h5>
          <p className="service-paragraph text-center mt-6">
            يمكنك تسجيل أعمالك الجديدة بطريقة اّمنة مع جمعية ساسيرو
          </p>
          <br />
          <Link to="/register" onClick={handleClick}>
            <button className="service-button bg-transparent border-white border text-white mt-10 md:px-6 px-6 py-4 rounded-3xl font-extrabold lg:text-xl md:text-base text-base">
              سجل الاَن
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
