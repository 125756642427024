import { useState } from "react";
import "./Register.scss";

const Register = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [songName, setSongName] = useState("");
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [sucessMessage, setSucessMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [songError, setSongError] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSongNameChange = (event) => {
    setSongName(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // file reader api by js = object
    const allowedTypes = ["audio/mpeg", "audio/wav"];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setErrorMessage("");
    } else {
      setFile(null);
      setErrorMessage("الملفات المدعومة mp3 , wav فقط");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); //  prevent default behaviour of form submit action  = reload  = SPA
    setSubmitting(true);

    if (!name.trim()) {
      setNameError(true);
      setSubmitting(false);
      return;
    }

    if (!number.trim()) {
      setNumberError(true);
      setSubmitting(false);
      return;
    }

    if (!songName.trim()) {
      setSongError(true);
      setSubmitting(false);
      return;
    }

    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("phone", number);
    formdata.append("song_name", songName);
    formdata.append("record_file", file);

    let requestOptions = {
      method: "POST",
      body: formdata,
    };

    fetch("https://ghanily.ivas.com.eg/api/upload", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setSucessMessage(result.message);
          setErrorMessage("");
          setSubmitting(false);
        } else {
          // Handle API error response
          const errorMessages = Object.values(result.errors).flat();
          setErrorMessage(errorMessages.join(" "));
          console.log(errorMessages, errorMessages.join(" "));
          setSucessMessage("");
          setSubmitting(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setSubmitting(true);
        setErrorMessage("حدث خطأ أثناء التسجبل ,حاول مرة اخرى");
        setSucessMessage("");
        setSubmitting(false);
      });

    // Clear form fields
    setName("");
    setNumber("");
    setSongName("");
    setFile(null);
  };
  const isFormValid =
    name !== "" && number !== "" && songName !== "" && file !== null;

  return (
    <div className="flex flex-col lg:flex-row items-center mt-16 justify-center gap-20 lg:mr-16 mr-0">
      <img
        src="/images/sacerau-about.png"
        alt="Sacerau"
        className="lg:max-w-[600px] md:w-full w-full lg:mr-40 mr-0 lg:px-0 px-4 mt-24"
      />
      <div className="lg:w-auto w-full mt-10">
        {sucessMessage && (
          <div
            id="alert-border-3"
            class="lg:w-full md:w-7/12 w-11/12  mx-auto p-4 mb-4  text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-800 dark:border-green-800"
            role="alert"
            style={{ direction: "rtl" }}
          >
            <div className="flex justify-between">
              <div class="ml-3 text-sm font-medium text-right">
                {sucessMessage}
              </div>
              <button
                type="button"
                class=" -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                data-dismiss-target="#alert-border-3"
                aria-label="Close"
                onClick={() => setSucessMessage("")}
              >
                <span class="sr-only">إغلاق</span>
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        )}
        {errorMessage && (
          <div
            id="alert-border-2"
            class="lg:w-full md:w-7/12 w-11/12  mx-auto p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800"
            role="alert"
            style={{ direction: "rtl" }}
          >
            <div className="flex justify-between items-center">
              <div class="ml-3 text-sm font-medium text-right">
                {errorMessage}
              </div>
              <button
                type="button"
                class="-mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
                data-dismiss-target="#alert-border-2"
                aria-label="Close"
                onClick={() => setErrorMessage("")}
              >
                <span class="sr-only">Dismiss</span>
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        )}

        <h5 className="lg:text-3xl text-2xl lg:px-0 px-4 lg:mt-16 mt-6 mb-4 font-bold text-right">
          تسجيل الأعمال الجديدة
        </h5>
        <p className="service-paragraph lg:px-0 px-4 mt-6 mb-4 text-red-600  font-light lg:text-sm text-xs text-right">
          يمكنك تسجيل أعمالك الجديدة بطريقة اّمنة مع جمعية ساسيرو
        </p>
        <form
          onSubmit={handleSubmit}
          dir="rtl"
          className="lg:max-w-lg w-full mx-auto lg:px-0 px-4"
        >
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-lg mb-1 text-bold required"
            >
              الإسم
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="w-full px-4 py-2 border-b border-gray-800 focus:outline-none focus:border-blue-500"
            />
          </div>
          {nameError && <div className="text-red-500">هذا الحقل مطلوب</div>}
          <div className="mb-4">
            <label
              htmlFor="mobile"
              className="block text-lg mb-1 text-bold required"
            >
              رقم الهاتف
            </label>
            <input
              type="tel"
              id="mobile"
              value={number}
              onChange={handleNumberChange}
              pattern="[0-9]{11}"
              className="w-full px-4 py-2 border-b border-gray-800 focus:outline-none focus:border-blue-500"
            />
          </div>
          {numberError && <div className="text-red-500">هذا الحقل مطلوب</div>}
          <div className="mb-10">
            <label
              htmlFor="name"
              className="block text-lg mb-1 text-bold required"
            >
              إسم الأغنية
            </label>
            <input
              type="text"
              id="songName"
              value={songName}
              onChange={handleSongNameChange}
              required
              className="w-full px-4 py-2 border-b border-gray-800 focus:outline-none focus:border-blue-500"
            />
          </div>
          {songError && <div className="text-red-500">هذا الحقل مطلوب</div>}
          <div className="mb-10">
            <label htmlFor="file" className="block text-lg mb-1 required">
              تحميل الملف (mp3 أو wav فقط)
            </label>
            <div className="flex">
              <label
                htmlFor="file-upload"
                className="bg-transparent border register-button px-4 py-2 focus:outline-none rounded cursor-pointer"
              >
                اختر ملف
              </label>
              <span className="mr-3 mt-2">
                {file ? file.name : "لم يتم تحميل ملف"}
              </span>
              <input
                type="file"
                id="file-upload"
                accept=".mp3,.wav"
                onChange={handleFileChange}
                required={!file}
                className="hidden"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={`w-full bg-transparent border px-4 py-2 register-button focus:outline-none ${
                submitting || !isFormValid ? `opacity-75` : ``
              }`}
              disabled={submitting || !isFormValid}
            >
              {submitting ? "إرسال ..." : "إرسال"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
