import "./CarouselSecton.scss";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// https://github.com/leandrowd/react-responsive-carousel => link of carousel library.

export default function CarouselSection() {
  const [imgs, setImgs] = useState([]);
  useEffect(() => {
    axios
      .get("https://sacerau.ivas.com.eg/api/sliders")
      .then((response) => setImgs(response.data.data))
      .catch((error) => console.error(error));
  }, []);

  // Prev image arrow button in carousel
  const renderArrowPrev = (onClickHandler, hasPrev, label) => {
    return (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        className={`carousel-arrow carousel-arrow-prev${
          !hasPrev ? " carousel-arrow-disabled" : ""
        }`}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
    );
  };

  // Next image arrow in carousel
  const renderArrowNext = (onClickHandler, hasNext, label) => {
    return (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        className={`carousel-arrow carousel-arrow-next${
          !hasNext ? " carousel-arrow-disabled" : ""
        }`}
      >
        <FontAwesomeIcon icon={faAngleRight} className="right-arrow" />
      </button>
    );
  };
  return (
    <div
      className="background-container relative w-full justify-center flex mt-5"
      id="main"
    >
      <Carousel
        key={imgs.length} // fix the carousel from not autoplay until you move the pictures
        autoPlay={true}
        interval={4000}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
        showStatus={false}
        infiniteLoop
        showThumbs={false}
        showIndicators={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const defStyle = {
            marginLeft: 15,
            color: "white",
            cursor: "pointer",
            fontSize: 45,
          };
          const style = isSelected
            ? { ...defStyle, color: "#0B9095" }
            : { ...defStyle };
          return (
            <span
              style={style}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            >
              .
            </span>
          );
        }}
      >
        {imgs.map((image) => (
          <img
            src={image.image}
            key={image.id}
            alt={image.id}
            className="w-full "
          />
        ))}
      </Carousel>
    </div>
  );
}
