import "./committees.scss";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faChartLine,
  faScaleBalanced,
  faPeopleGroup,
  faStreetView,
  faHospitalUser,
  faBookOpenReader,
} from "@fortawesome/free-solid-svg-icons";

export default function Committees() {
  const sectionRef = useRef(null);
  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          section.classList.add("visible");
          observer.disconnect();
        }
      });
    });
    observer.observe(section);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className="video-section flex flex-col mt-28"
      id="video"
      ref={sectionRef}
    >
      <div style={{ direction: "rtl" }} className="lg:mr-20 md:mr-5 mr-5 mt-10">
        <h1 className="font-bold text-xl colored-commites">
          اللجان المشكلة من أعضاء مجلس الادارة
        </h1>
        <h4 className="mt-5 lg:ml-0 md:ml-5 ml-0">
          مهمة هذه اللجان بحث المواضيع المختلفة حسب طبيعة عمل كل لجنة و رفع
          توصياتها لمجلس الادارة للمناقشة وأخذ القرارات .
        </h4>
        <div className="md:grid md:grid-cols-3 flex flex-col items-center gap-4 gap-y-20 mt-10">
          <div className="flex flex-col justify-center items-center w-60 gap-y-5">
            <div className="bg-red-500 w-24 h-24 rounded-full  flex items-center justify-center">
              <FontAwesomeIcon
                icon={faHandshake}
                color="#fff"
                className="text-4xl"
              />
            </div>
            <span className="font-bold text-lg">اللجنة التنفيذية</span>
          </div>

          <div className="flex flex-col justify-center items-center w-60 gap-y-5">
            <div className="bg-red-500 w-24 h-24  rounded-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faChartLine}
                color="#fff"
                className="text-4xl"
              />
            </div>
            <span className="font-bold text-lg">لجنة تنمية الموارد</span>
          </div>

          <div className="flex flex-col justify-center items-center w-60 gap-y-5">
            <div className="bg-red-500 w-24 h-24  rounded-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faScaleBalanced}
                color="#fff"
                className="text-4xl"
              />
            </div>
            <span className="font-bold text-lg">لجنة التظلمات و القانونية</span>
          </div>

          <div className="flex flex-col justify-center items-center w-60 gap-y-5">
            <div className="bg-red-500 w-24 h-24 rounded-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faPeopleGroup}
                color="#fff"
                className="text-4xl"
              />
            </div>
            <span className="font-bold text-lg">لجنة القيد وقبول العضوية</span>
          </div>

          <div className="flex flex-col justify-center items-center w-60 gap-y-5">
            <div className="bg-red-500 w-24 h-24 rounded-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faStreetView}
                color="#fff"
                className="text-4xl"
              />
            </div>
            <span className="font-bold text-lg">لجنة الإعلام</span>
          </div>

          <div className="flex flex-col justify-center items-center w-60 gap-y-5">
            <div className="bg-red-500 w-24 h-24 rounded-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faHospitalUser}
                color="#fff"
                className="text-4xl"
              />
            </div>
            <span className="font-bold text-lg">لجنة الرعاية</span>
          </div>
        </div>
        <h4 className="font-bold text-xl colored-commites mt-14">
          الهيكل التنظيمي الوظيفي
        </h4>
        <ul className="mt-5 flex flex-col gap-2">
          <li>مدير عام الجمعية ويرأس كل من : </li>
          <li>
            <FontAwesomeIcon
              icon={faBookOpenReader}
              className="ml-2"
              style={{ color: "#9f1234" }}
            />
            القسم المالي ويختص بالحسابات العامة - الخزينة – التحصيل – حسابات
            التوزيع – حسابات الضرائب – حسابات صندوق الجمعية 7%
          </li>
          <li>
            <FontAwesomeIcon
              icon={faBookOpenReader}
              className="ml-2"
              style={{ color: "#9f1234" }}
            />
            القسم الاداري ويختص بشئون العضوية – اعمال السكرتارية – شئون العاملين
            – الخدمات المعاونة
          </li>
          <li>
            <FontAwesomeIcon
              icon={faBookOpenReader}
              className="ml-2"
              style={{ color: "#9f1234" }}
            />
            قسم البرامج والمصنفات الفنية والعلاقات الخارجية
          </li>
        </ul>
      </div>
    </div>
  );
}
