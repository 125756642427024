import "./Partners.scss";
import { useRef, useEffect } from "react";

export default function Partners() {
  const sectionRef = useRef(null);
  useEffect(() => {
    const section = sectionRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          section.classList.add("visible");
          observer.disconnect();
        }
      });
    });
    observer.observe(section);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="mt-20" ref={sectionRef} id="partners">
      <h3 className="lg:text-3xl md:text-2xl text-2xl pt-4 text-center font-semibold md:mr-20 mr-0 section-main-heading">
        شركاء النجاح
      </h3>
      <div className="sponser-component bg-white flex md:flex-row flex-col justify-center md:py-5 py-3 items-center md:gap-16 gap-10 mt-5">
        <img
          src={process.env.PUBLIC_URL + "/images/client-1.png"}
          alt="Heinken"
          className="cursor-pointer md:filter md:invert transition-transform duration-500 transform-gpu hover:scale-125"
        />
        <img
          src={process.env.PUBLIC_URL + "/images/client-2.png"}
          alt="Typecast"
          className="cursor-pointer md:filter md:invert transition-transform duration-500 transform-gpu hover:scale-125"
        />
        <img
          src={process.env.PUBLIC_URL + "/images/client-3.png"}
          alt="Shopify"
          className="cursor-pointer md:filter md:invert md:hidden lg:block block transition-transform duration-500 transform-gpu hover:scale-125"
        />
        <img
          src={process.env.PUBLIC_URL + "/images/client-4.png"}
          alt="Dropbox"
          className="cursor-pointer md:filter md:invert md:hidden lg:block block transition-transform duration-500 transform-gpu hover:scale-125"
        />
        <img
          src={process.env.PUBLIC_URL + "/images/client-5.png"}
          alt="Bootstrap"
          className="cursor-pointer md:filter md:invert transition-transform duration-500 transform-gpu hover:scale-125 test22"
        />
        <img
          src={process.env.PUBLIC_URL + "/images/client-10.png"}
          alt="sass"
          className="cursor-pointer md:filter md:invert transition-transform duration-500 transform-gpu hover:scale-125"
        />
      </div>
    </div>
  );
}
